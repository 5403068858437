import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import nvidia from '../assets/nvidia.png';
import razorpay from '../assets/Razorpay.png';
import Mongo from '../assets/Mongo.svg';
import AWS from '../assets/copie-de-aws-activate.png';
import India from '../assets/startupindia.png';
import Odisha from '../assets/odishalogo.png';

const StyledContainer = styled(Box)({
  backgroundColor: 'white',
  padding: '20px',
  textAlign: 'center',
});

const Heading = styled(Typography)({
  color: '#ff2a0c',
  fontSize: '36px',
  fontWeight: 'bold',
  marginBottom: '20px',
  fontFamily: 'Pacifico, cursive',
});

const IconsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  gap: '20px',
  flexWrap: 'wrap',
});

const GreyIcon = styled('img')({
    width: '120px', // Set a fixed width for the icons
    height: '120px', // Set a fixed height to maintain aspect ratio
    objectFit: 'contain', // Maintain aspect ratio without stretching
    marginBottom: '20px', // Add some bottom margin for spacing
  });

const Showcase = () => {
  return (
    <StyledContainer>
      <Heading variant="h2">Accelerated By</Heading>
      <IconsContainer>
        <GreyIcon src={India} alt="Company 1" /> 
        <GreyIcon src={Odisha} alt="Company 2" />
        <GreyIcon src={AWS} alt="Company 3" />
        <GreyIcon src={Mongo} alt="Company 4" />
        <GreyIcon src={nvidia} alt="Company 5" />
        <GreyIcon src={razorpay} alt="Company 6" />
        {/* Add more icons as needed */}
      </IconsContainer>
    </StyledContainer>
  );
};

export default Showcase;
