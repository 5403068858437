import React from 'react';
import { styled } from '@mui/system';
import { Box, Typography, Paper, Button } from '@mui/material';
import AppIcon from '../assets/Passenger.jpg'; // Import your app icon image

const StyledContainer = styled(Box)({
  backgroundColor: '#ff2a0c',
  padding: '20px',
  textAlign: 'center',
});

const Heading = styled(Typography)({
    color: 'black',
    fontSize: '50px', // Increased font size
    fontWeight: 'bold',
    marginBottom: '20px',
    fontFamily: 'Pacifico, cursive', // Use the Pacifico font
  });
  

const ProductPaper = styled(Paper)({
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '16px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  maxWidth: '600px',
  margin: 'auto',
});

const AppIconImage = styled('img')({
    maxWidth: '30%', // Smaller icon size
    height: 'auto',
    borderRadius: '50%', // Make it a circle
    marginBottom: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', // Blackish shadow
  });

  const Description = styled(Typography)({
    color: 'black',
    fontSize: '30px', // Increased font size
    marginBottom: '20px',
    fontFamily: 'Caveat, cursive', // Use the Caveat font
  });
  

  const CheckOutButton = styled(Button)({
    backgroundColor: 'black',
    color: '#ff2a0c',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    padding: '10px 30px', // Adjust the padding for width and height
    fontSize: '18px', // Adjust the font size
    '&:hover': {
      backgroundColor: '#222',
    },
  });

  const handleCheckOutClick = () => {
    window.open('https://holocabs.com', '_blank', 'noopener noreferrer');
  };



const OurProducts = () => {
  return (
    <StyledContainer>
      <Heading variant="h2">First of our Products..!</Heading>
      <ProductPaper elevation={3}>
        <AppIconImage src={AppIcon} alt="App Icon" />
        <Description>
          A Cab Aggregator Application with a unique pricing algorithm model.
        </Description>
        <CheckOutButton onClick={handleCheckOutClick} variant="contained">Check out</CheckOutButton>
      </ProductPaper>
    </StyledContainer>
  );
};

export default OurProducts;
