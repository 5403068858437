import React from 'react';
import { Box, styled, Typography, TextField, Button, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import StartupIndiaLogo from '../assets/logo-startup-1.png';

const FooterContainer = styled(Box)({
  backgroundColor: 'black',
  padding: '40px',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
});

const SocialMediaContainer = styled(Box)({
  marginTop: '20px',
  display: 'flex',
  gap: '20px',
});

const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      '& fieldset': {
        borderColor: '#ff2a0c',
      },
      '&:hover fieldset': {
        borderColor: '#ff2a0c',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ff2a0c',
      },
      '& input': {
        color: 'black', // Set text color to black
      },
      '& input::placeholder': {
        color: '#ff2a0c',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#ff2a0c',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#ff2a0c',
    },
    '& .MuiOutlinedInput-input': {
      padding: '15.5px 14px',
    },
    '& .MuiOutlinedInput-inputAdornedEnd': {
      paddingRight: '36px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 16px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  });
  
  const CustomTypography = styled(Typography)({
    fontFamily: "'Pacifico', cursive",
    color: '#ff2a0c',
    display: 'inline-block', // Ensure inline-block display to apply color to specific text
  });

  const handlesocial1 = () => {
    window.open('https://www.linkedin.com/company/golden-fox-technology-pvt-ltd/', '_blank', 'noopener noreferrer');
  };
  

const Footer = () => {
  return (
    <FooterContainer>
      <Typography variant="h4">Subscribe to our Newsletter</Typography>
      <Typography variant="subtitle1">Stay updated with our latest news and innovations.</Typography>

      <Box marginTop="20px">
        <CustomTextField
          label="Email Address"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{ style: { color: 'white' } }}
        />
      </Box>

      <Box marginTop="20px">
      <Button variant="contained" style={{ backgroundColor: '#ff2a0c', color: 'white' }}>
  Subscribe
</Button>

      </Box>

      <SocialMediaContainer>
        <IconButton onClick={handlesocial1}>
          <LinkedInIcon style={{ color: '#ff2a0c' }} />
        </IconButton>
        <IconButton>
          <InstagramIcon style={{ color: '#ff2a0c' }} />
        </IconButton>
        <IconButton>
          <YouTubeIcon style={{ color: '#ff2a0c' }} />
        </IconButton>
      </SocialMediaContainer>

      <Box marginTop="20px">
        <img src={StartupIndiaLogo} alt="Startup India Logo" height="50" />
      </Box>

      <Typography variant="body2" marginTop="20px">
  © 2024 <CustomTypography>Golden Fox Technology</CustomTypography>. All rights reserved.
</Typography>
    </FooterContainer>
  );
};

export default Footer;
