import React from 'react';
import { Box, styled, Typography,useMediaQuery} from '@mui/material';
import IllustrationImage from '../assets/aboutuss.svg';

const StyledContainer = styled(Box)({
  backgroundColor: 'black',
  padding: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TextContainer = styled('div')({
  maxWidth: '50%', // Adjust the width as needed
});

const Heading = styled(Typography)({
  color: '#ff2a0c',
  fontSize: '60px',
  fontWeight: 'bold',
  marginBottom: '10px',
  fontFamily: 'Montserrat, sans-serif',
});

const Paragraph = styled(Typography)({
  color: 'white',
  fontSize: '20px',
  lineHeight: '1.6',
});

const IllustrationImageContainer = styled('img')({
  maxWidth: '80%', // Adjust the size as needed
  height: 'auto',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Shadow for the illustration
});

const AboutUs = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <StyledContainer>
      {isMobile ? null : <IllustrationImageContainer src={IllustrationImage} alt="About Us Illustration" />}
      <TextContainer>
        <Heading variant="h2">About Us</Heading>
        <Paragraph>
          We are an ambitious group embarked on an ambitious journey to innovate and recreate an ecosystem around everyone where innovations and sustainance can go unidirectional. We intend to innovate in a way that would not only add to the betterment and upliftment of the quality of social life but also simultaneously add greatly to the betterment and re-establishment of the Nature which represents the physiological builds of the good Earth.

          We thoroughly keep digging for opportunities where our innovations can prosper and add to the quality of life and of the Nature we belong in.
        </Paragraph>
      </TextContainer>
    </StyledContainer>
  );
};

export default AboutUs;
