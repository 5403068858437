import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import ILLU from '../assets/illu.svg';

const StyledContainer = styled(Box)({
  backgroundColor: 'black',
  padding: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '100vh', // Minimum height of the container
});

const TextContainer = styled('div')({
  maxWidth: '60%', // Adjust the width as needed
});

const Heading = styled(Typography)({
  color: '#ff2a0c',
  fontSize: '60px',
  fontWeight: 'bold',
  marginBottom: '10px',
  fontFamily: 'Pacifico, cursive',
  marginLeft: '3%',
});

const Paragraph = styled(Typography)({
  color: 'white',
  fontSize: '30px',
  lineHeight: '1.6',
  marginLeft: '3%',
});

const IllustrationImage = styled('img')({
  maxWidth: '100%',
  height: 'auto',
});

const VisionComponent = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <StyledContainer>
      <TextContainer>
        <Heading variant="h2">Our Vision</Heading>
        <Paragraph>
          We envision to be an elite group that would lead the Green Earth Renaissance and Cater to the peace enhancement by maximum satisfaction of every user that connects with us, via ethical innovations and live time problem-solving machineries designed and to be designed at times as we make our progress touching new heights of Innovations, Quality, Goodness, and the Renaissance
        </Paragraph>
      </TextContainer>
      {!isMobile && <IllustrationImage src={ILLU} alt="Illustration" />}
    </StyledContainer>
  );
};

export default VisionComponent;