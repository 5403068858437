import NavBar from './components/NavBar';
import VisionComponent from './components/VisionComponent';
import OurProducts from './components/OurProducts';
import AboutUs from './components/AboutUs';
import TeamMembers from './components/Team';
import Footer from './components/Footer';
import Showcase from './components/Showcase';

function App() {
  return (
    <div className="App">
      <NavBar/>
      <VisionComponent/>
      <OurProducts/>
      <AboutUs/>
      <TeamMembers/>
      <Showcase/>
      <Footer/>
    </div>
  );
}

export default App;
