import React from "react";
import {
  Box,
  styled,
  Paper,
  Typography,
  Avatar,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import AvatarImage from "../assets/TEAMAVATAR.svg";

const StyledContainer = styled(Box)({
  backgroundColor: "#ff2a0c",
  padding: "20px",
  overflowX: "auto",
  whiteSpace: "nowrap",
  display: "flex",
  flexDirection: "column", // Change to column layout
  alignItems: "center",
  "&::-webkit-scrollbar": {
    display: "none", // Hide the scrollbar
  },
});

const HeadingTypography = styled(Typography)({
  color: "white",
  fontSize: "36px",
  fontWeight: "bold",
  marginBottom: "20px",
  fontFamily: 'Pacifico, cursive',
});

const CardContainer = styled(Paper)({
  padding: "20px",
  borderRadius: "12px",
  textAlign: "center",
  minWidth: "200px",
  margin: "10px", // Add margin for spacing between cards
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
});

const MemberAvatar = styled(Avatar)({
  width: "100px",
  height: "100px",
  margin: "auto",
});

const EmailLogo = styled(IconButton)({
  fontSize: "24px",
  marginTop: "10px",
  color: "black",
  "&:hover": {
    color: "#ff2a0c",
  },
});

const CustomTypography = styled(Typography)({
  fontFamily: "'Pacifico', cursive",
});

const CustomTypography2 = styled(Typography)({
  fontFamily: "'Oswald', sans-serif",
  fontWeight: 700,
});

const TeamMembers = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const teamMembers = [
    {
      id: 1,
      name: "Radharaman Priyadarshi",
      designation: "Director",
      email: "radharaman12@goldenfoxtechnology.com",
      avatarSrc: AvatarImage,
    },
    {
      id: 2,
      name: "Shibananda Mohanty",
      designation: "Director",
      email: "director@goldenfoxtechnology.com",
      avatarSrc: AvatarImage,
    },
    {
      id: 3,
      name: "Nabin Behera",
      designation: "Operations",
      email: "nabinbehera10@gmail.com",
      avatarSrc: AvatarImage,
    },
    {
      id: 4,
      name: "Surya Narayan Nayak",
      designation: "Technical Lead",
      email: "surya98613@gmail.com",
      avatarSrc: AvatarImage,
    },
    {
      id: 5,
      name: "Sritam Das",
      designation: "Technical Lead",
      email: "sritamk.das@gmail.com",
      avatarSrc: AvatarImage,
    },
  ];

  const handleEmailClick = (email) => {
    window.open(`mailto:${email}`, '_blank');
  };

  return (
    <StyledContainer>
      <HeadingTypography variant="h2">Our Team</HeadingTypography>

      <Box
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "20px",
          overflowX: "auto",
        }}
      >
        {teamMembers.map((member) => (
          <CardContainer key={member.id}>
            <MemberAvatar alt={member.name} src={member.avatarSrc} />
            <CustomTypography variant="h6">{member.name}</CustomTypography>
            <CustomTypography2 variant="subtitle1">
              {member.designation}
            </CustomTypography2>
            <EmailLogo onClick={() => handleEmailClick(member.email)}>
              <EmailIcon />
            </EmailLogo>
          </CardContainer>
        ))}
      </Box>
    </StyledContainer>
  );
};

export default TeamMembers;
