// NavBar.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Typography, Hidden, IconButton, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu'; // Material-UI Menu icon
import FoxIcon from '../assets/icon.jpg'; // Import your company icon component

const StyledAppBar = styled(AppBar)({
  backgroundColor: 'black',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '3px', // Adjust the height of the glowing border
    backgroundColor: '#ff2a0c',
    borderRadius: '50%',
    animation: '$glow 1.5s linear infinite',
  },
  '@keyframes glow': {
    '0%': {
      opacity: 0.3,
    },
    '50%': {
      opacity: 0.6,
    },
    '100%': {
      opacity: 0.3,
    },
  },
});

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between', // Align items to the start and end of the toolbar
});

const StyledButton = styled(Button)({
  color: 'white',
  '&:hover': {
    color: '#ff2a0c',
  },
});

const StyledTypography = styled(Typography)({
  color: 'white',
  '& #fox': {
    color: '#ff2a0c',
  },
});

const StyledLogoContainer = styled('div')({
  display: 'flex',
  alignItems: 'center', // Align logo and text vertically in the middle
});

const StyledMenu = styled(Menu)({
  '& .MuiMenu-paper': {
    backgroundColor: 'black',
  },
});

const StyledMenuItem = styled(MenuItem)({
  color: 'white',
  '&:hover': {
    color: '#ff2a0c',
  },
});

const NavBar = () => {
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <StyledAppBar position="static">
      <StyledToolbar>
        <StyledLogoContainer>
          <img src={FoxIcon} alt="Golden Fox Technology Logo" width={30} height={30} />
          <StyledTypography variant="h6" component="div" sx={{ marginLeft: 1 }}>
            Golden <span id="fox">Fox</span> Technology
          </StyledTypography>
        </StyledLogoContainer>
        <Hidden smDown>
          {/* Show the buttons only for devices larger than or equal to "sm" (small) */}
          <div>
            <StyledButton>About Us</StyledButton>
            <StyledButton>Products</StyledButton>
            <StyledButton>Team</StyledButton>
            <StyledButton>Contact Us</StyledButton>
          </div>
        </Hidden>
        <Hidden mdUp>
          {/* Show the Menu icon for devices smaller than "md" (medium) */}
          <IconButton color="inherit" onClick={handleMenuOpen}>
            <MenuIcon />
          </IconButton>
          {/* Mobile Menu */}
          <StyledMenu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={handleMenuClose}
          >
            <StyledMenuItem onClick={handleMenuClose}>About Us</StyledMenuItem>
            <StyledMenuItem onClick={handleMenuClose}>Products</StyledMenuItem>
            <StyledMenuItem onClick={handleMenuClose}>Team</StyledMenuItem>
            <StyledMenuItem onClick={handleMenuClose}>Contact Us</StyledMenuItem>
          </StyledMenu>
        </Hidden>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default NavBar;
